.burger {
  width: 26px;
  
  &__burger-button {
    display: block;
    width: 26px;
    height: 30px;
    padding: 0;
    background-color: rgba(0,0,0, 0);
    border: none;
    cursor: pointer;
    outline-style: none;

    &--white {
      span {
        background-color: rgb(var(--palette-white));
      }
    }

    &--black {
      span {
        background-color:  rgb(var(--palette-black));
      }
    }
  
    span {
      cursor: pointer;
      display: block;
      width: 100%;
      height: 2px;
  
      &:nth-child(2),
      &:nth-child(3) {
        margin-top: 6px;
      }
    }
  }
}