.header {
	display: block;
	background-color: #e6e6e6;
	font-size: 14px;
	font-weight: 400;
	line-height: 34px;
	color: rgb(var(--palette-dark-shade2));
	padding: 0 calc(var(--dp) * 2); /* 0 24px */
	top: 66px;

	&--failure {
		background: rgb(var(--palette-orange));
	}

	&--success {
		background: rgb(var(--palette-green));
	}

	&--sticky {
		position: sticky;
		position: -webkit-sticky;
	}
}
