.areas-left {
	color: white;
	flex: 1;
	height: 100%;
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	background-color: rgb(var(--color-background-section));

	&__edit {
		display: flex;
		background-color: rgb(var(--color-background-section));
		flex-flow: column;
		align-items: center;
		height: 100%;
		width: 100%;
		max-width: 64px;
		text-align: center;
	}
}
