.navigation-container {
	position: relative;
  width: 100%;
  height: 66px;
  z-index: 1;

  &__top-bar {
    height: 20px;
    width: 100%;
    background: black;
    position: fixed;
    top: 0;
    left: 0;
  }

  &__relative-postion-header {
    height: 66px;
    width: 100%;
    position: relative;
  }
}
