.navbar {
	height: 66px;
	width: 100%;
	position: fixed;

	&--relative {
		height: 66px;
		width: 100%;
		position: relative;
	}

	&__content {
		background-color: rgb(var(--color-background));
		height: var(--nav-heigh);
		padding: 0 calc(var(--dp) * 2); /* 0 16px */
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		align-items: center;
		z-index: 2;
	}
}

.navbar__content__body {
	font-size: 20px;
	color: rgb(var(--color-text-light));
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}

.navbar__content__left,
.navbar__content__right {
	width: 40px;
	color: rgb(var(--color-text-light));
}
