.is-open {
	box-shadow: 0 0 5 0 grey;
}

.order-page {
	&__count {
		&--greater {
			color: rgb(var(--palette-green-shade1));
		}

		&--lesser {
			color: rgb(var(--palette-orange));
		}
	}

	&__collapsable {
		text-align: center;
		padding: 10px;

		.button {
			width: 100%;
		}
	}

	&__list__name-content {
		display: flex;
		flex-direction: column;
		justify-content: space-around;
		height: 50px;

		span {
			line-height: 1;
		}

		&__description {
			color: rgba(var(--palette-dark-shade1), 0.7);
			font-size: 12px;
			line-height: 24px;
		}
	}
}
