.count-unit-section {
	height: 54px;
	/* border-bottom: solid 1px gray; */
	padding: 0 16px;
	background: rgb(var(--palette-light));
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	border-bottom: solid 1px rgb(var(--palette-light-shade2));

	/* &:nth-child(2) {
		box-shadow: inset 0 8px 5px -10px #000;
	} */

	&:last-of-type {
		box-shadow: inset 0 -5px 13px -15px #000;
		border-bottom: none;
	}

	&__left {
		display: flex;
		align-items: center;
		flex: 1 1 0;
		justify-content: space-between;

		div {
			display: flex;
			flex-direction: column;

			span {
				/* margin-top: 15px; */
				font-size: 12px;

				/* width: 40px; */
				text-transform: uppercase;
				padding-right: 12px;
				overflow: hidden;

				/* text-overflow: ellipse; */

				&::first-letter {
					text-transform: capitalize;
				}
			}
		}

		input {
			outline: none;
			border: none;
			border: solid 2px #e8e5e5;
			height: 44px;
			background: rgb(var(--palette-white));
			border-radius: 0;
			padding: 0 8px;
			width: 55px;
			box-sizing: border-box;
		}
	}

	&__right {
		display: flex;
		align-items: center;

		span {
			font-size: 12px;
			width: 48px;
			padding: 8px;
			text-transform: uppercase;
		}
	}

	&__image {
		width: 45px;
		height: 45px;
		border-radius: 3px;
	}

	&__invalid {
		background-color: rgba(var(--danger), 0.4);

		input {
			border-color: rgb(var(--danger)) !important;
		}
	}
}
