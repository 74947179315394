.area-configuration {
  &__title {
    text-align: center;
  }

  &__label {
    font-weight: bold;
    padding-bottom: 10px;
  }

  &__section {
    margin: 30px 15px;
  }

  &__title-input-container {
    input {
      border: solid 1px gray;
      min-width: 150px;
      height: 40px;
      width: 100%;
      outline: none;
      padding: 0 10px;
      box-sizing: border-box;
      margin: 0;
    }
  }

  &__category-container {
    &__button-wrapper {
      display: flex;
      width: 100%;
    }
  }

  &__button-wrapper {
    position: absolute;
    bottom: 0;
    width: 100%;
    left: 0;
  }

  &__loader {
    position: absolute;
    top: 50%;
  }
}