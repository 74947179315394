.areas-list {
	position: relative;

	&__add-area {
		text-align: center;
		margin-top: 40%;

		&__arrow {
			position: absolute;
			top: 85px;
			right: 37px;
		}

		&__icon {
			padding-bottom: 20px;
		}
	}

	.list {
		margin-bottom: 60px;
	}

	&__zero-count-container {
		width: 100%;
		bottom: 0;
		position: fixed;
		display: flex;
		height: 60px;

		button {
			margin: auto;
			width: 200px;
			border-radius: 3px;
			box-shadow: 0px 0 5px 0px rgb(95, 95, 95);
		}
	}

	&__modal {
		&__content {
			position: absolute;
			top: 0;
			bottom: 50px;
			width: 100%;
			color: rgb(var(--palette-dark-shade1));
			display: flex;

			span {
				margin: auto;
			}
		}

		&__loader {
			position: absolute;
			top: 0;
			bottom: 0;
			width: 100%;
			background: rgba(var(--palette-dark-shade1), 0.3);

			.j-loader {
				font-size: 2px;

				.j-loader__spinner {
					width: 30px;
					max-width: 100px;
					height: 30px;
					max-height: 100px;
					border: 8px solid #f5f5f5;
					border-left: 8px solid rgb(147, 214, 168);
				}
			}
		}

		&__buttons {
			position: absolute;
			width: 100%;
			bottom: 0;
			height: 50px;

			button {
				width: 125px;
				height: 50px;
			}
		}
	}

	&__tip {
		position: fixed;
		bottom: 100px;
		left: 50%;
		transform: translateX(-50%);
		padding: 0 var(--dp);
		max-width: 50vw;
		&__icon {
			margin-bottom: calc(var(--dp) / 2);
			position: absolute;
			left: -2px;
			top: 2px;
		}

		&__text {
			margin-left: var(--dp);
			text-align: center;
			display: inline-block;
		}
	}
}
