.toast {
  position: relative;
  width: 100%;
  z-index: 4;
  display: inline-block;
  margin-bottom: 20px;

  &__wrapper {
    display: flex;
    margin: 0 15px;
    padding: 9px 0 9px 15px;
    border-radius: 5px;
    height: 100%;
    justify-content: space-between;
    color: white;
    box-shadow: 0px 1px 3px 0px #838383;

    &--error {
      background: rgba(var(--danger), 1);
    }
  
    &--success {
      background: rgba(var(--success), 1);
    }

    &--warning {
      background: rgba(var(--warning), 1);
    }
  }

  &__content {
    flex-shrink: 2;
    padding-right: 6px;
    margin-top: auto;
    margin-bottom: auto;
  }

  &__right-content {
    margin-top: auto;
    width: 35px;
    min-width: 35px;
    text-align: center;
    height: 35px;
    padding-top: 6px;
    box-sizing: border-box;
    flex-basis: 35px;
  }
}

.toast-container {
  position: fixed;
  bottom: 7px;
  width: 100%;
}