.count-buttons {
	height: 66px;
	transition: 0.1s height ease-in;
	display: flex;
	flex-direction: row;

	&__input {
		border: none;
		border: 1px 1px 0 1px solid #d4d4d4;
		padding-left: 40px;
		font-size: 18px;
	}

	&__button {
		border: none;
		/* Used hex as var as rgb on border doesn't work for ios safari */
		border-right: 1px #d4d4d4 solid;
		/* Used hex as var as rgb on border doesn't work for ios safari */
		border-top: 1px solid #d4d4d4;
		border-radius: 0;

		&:first-of-type {
			/* border-radius: 0 0 0 5px; */
			border-bottom-left-radius: 5px;
		}

		&:last-of-type {
			border: none;
			/* Used hex as var as rgb on border doesn't work for ios safari */
			border-top: 1px solid #d4d4d4;
			border-bottom-right-radius: 5px;
		}

		&--small {
			max-width: 40px;
			min-width: 40px;
			width: 40px;
		}
	}

	.button-toggled {
		background: rgb(var(--palette-light));
	}
}
