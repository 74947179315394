.list-item {
	line-height: 50px;
	padding: 0 calc(var(--dp) * 2);
	display: flex;
	flex-direction: row;
	justify-content: space-between;

	span {
		user-select: none;
		max-width: 75%;
		text-overflow: ellipsis;
		white-space: nowrap;
	}

	&--full-width {
		width: 100%;
	}

	&--selected {
		background: rgb(var(--palette-pink));
	}

	.list-item-link {
		width: 100%;
		text-transform: capitalize;
	}
}

.right-content {
	font-size: 11px;

	span {
		user-select: none;
	}
}
