.empty_list {
  &__image-wrapper {
    width: 100%;
    padding: 30px;
    box-sizing: border-box;
    text-align: center;
    justify-content: center;
    font-size: 1.2rem;

    &__image {
      width: 100%;
      border-radius: 10px;
    }

    &__text {
      padding-top: 10px;
      width: 100%;
      text-align: center;
      color: rgb(var(--color-text-dark));
    }
  }
}