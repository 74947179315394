.list {
	width: 100%;
	height: 100%;
	list-style-type: none;
}

.list__item {
	/* Used hex as var as rgb on border doesn't work for ios safari */
	background-color: rgb(var(--palette-white));
	margin-right: 6px;
	transition: margin-right 100ms ease-in-out;
	border-bottom: solid 1px rgb(var(--palette-light-shade2));
	border-right: solid 1px rgb(var(--palette-light-shade2));

	&--small {
		margin-right: 25px;
		transition: margin-right 200ms ease-in-out;
	}

	&:last-child {
		/* box-shadow: -2px 5px 3px 0 #838383; */
	}

	&:hover {
		background: rgba(var(--palette-pink), 0.2);
	}
}
.list .list__item:last-child {
	margin-bottom: 40px;
}

.store-tasks__day-section .list .list__item:last-child {
	margin-bottom: 0;
}
