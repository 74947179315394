.received-page {
	&__list__name-content {
		display: flex;
		flex-direction: column;
		justify-content: space-around;
		height: 50px;

		span {
			line-height: 1;
		}

		&__description {
			color: rgba(var(--palette-dark-shade1), 0.7);
			font-size: 12px;
			line-height: 24px;
		}
	}
}
