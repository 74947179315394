.areas-list {
  &__add-area {
    text-align: center;
    margin-top: 40%;

    &__arrow {
      position: absolute;
      top: 85px;
      right: 37px;
    }

    &__icon {
      padding-bottom: 20px;
    }
  }
}

