.store-tasks {
	& > &__day-section {
		margin: 0;
	}

	&:first-of-type {
		margin: 0;
	}

	&__button-wrapper {
		display: flex;
		justify-content: center;
		align-items: center;
		height: 70px;
	}

	&__future-tasks {
		opacity: 0.6;
	}

	&__day-header {
		background: rgb(var(--palette-green));
		color: rgb(var(--palette-white));
		padding: 5px calc(var(--dp) * 2);
		font-size: 14px;
		height: 21px;
		padding-top: 7px;

		&--highlight {
			background: rgb(var(--palette-pink));
		}
	}
}
