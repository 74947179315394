/* Wrapper outside of scope (node) */
.j-modal--wrapper {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;

	/* TODO: fix z-index */
	z-index: 10;
	display: flex;
	align-items: center;
	justify-content: center;
}

.j-modal--dim {
	position: absolute;
	background: rgba(var(--palette-dark-shade1), 0.3);
	opacity: 0.5;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
}

/* Animations */
.j-modal__outer-appear {
	opacity: 0.01;
	transform: translate3d(0, 20px, 0);
}

.j-modal__outer-appear.j-modal__outer-appear-active {
	transition: opacity 0.2s ease-out, transform 0.2s ease-out;
	transform: translate3d(0, 0, 0);
	opacity: 1;
}

.j-modal__outer-enter {
	opacity: 0.01;
}

.j-modal__outer-enter.j-modal__outer-enter-active {
	transform: translate3d(0, 0, 0);
	opacity: 1;
}

/* Modal */
.j-modal {
	position: relative;
	width: 250px;
	background: white;
	box-shadow: 0 2px 28px gray;
	overflow: hidden;
	border-radius: 3px;
	height: 160px;

	&__content {
		position: absolute;
		bottom: 60px;
		color: rgb(var(--palette-dark-shade1));
		font-size: 20px;
	}

	&__action {
		position: absolute;
		bottom: 0;
		height: 60px;
		width: 100%;
	}

	&__loader {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		display: flex;
		align-items: center;
		justify-content: center;
		background-color: rgba(var(--grey-400), 0.5);
		z-index: 2;
	}
}

.j-modal--no-scroll {
	.j-modal__content {
		max-height: initial;
		overflow: visible;
	}
}