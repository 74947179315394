.button {
	--big: 55px;
	--default: 44px;
	--small: 32px;

	min-height: var(--default);
	min-width: var(--default);
	display: inline-flex;
	justify-content: center;
	align-items: center;
	line-height: var(--default);
	font-size: 15px;
	font-style: normal;
	text-align: center;
	text-decoration: none;
	text-transform: capitalize;
	border-radius: 1px;
	cursor: pointer;
	outline: none;
	border: none;
	user-select: none;

	&[disabled] {
		opacity: 0.4;
	}
}

/* Types */

.button--default {
	--rgba-grey: rgba(var(--palette-light-shade1), 1);

	background-color: rgb(var(--color-background));
	color: rgb(var(--palette-white));

	&:active {
	/* Used rgb as var as rgb on border doesn't work for ios safari */
		box-shadow: inset 0px 0px 10px 0px var(--rgba-grey);
	}
}

.button--alternative {
	--rgba-grey: rgba(var(--palette-light-shade1), 1);

	background-color: rgb(var(--palette-white));
	color: rgb(var(--color-text-dark));
	border: solid 1px rgb(var(--color-text-dark));

	&:active {
		/* Used rgb as var as rgb on border doesn't work for ios safari */
		box-shadow: inset 0px 0px 10px 0px var(--rgba-grey);
	}
}

.button--inverted {
	--rgba-grey: rgba(var(--palette-light-shade1), 1);
	background-color: rgb(var(--palette-white));
	color: rgb(var(--color-text-dark));

	&:active {
		/* Used rgb as var as rgb on border doesn't work for ios safari */
		box-shadow: inset 0px 0px 10px 0px var(--rgba-grey);
	}
}

.button--pink {
	--rgba-pink: rgba(var(--palette-pink), 1);

	background-color: rgb(var(--palette-pink));
	color: rgb(var(--color-text-dark));
	border-radius: 3px;

	&:active {
		/* Used rgb as var as rgb on border doesn't work for ios safari */
		box-shadow: inset 0px 0px 10px 0px var(--rgba-pink);
	}
}

.button--success {
	background-color: rgb(var(--palette-green));
	color: rgb(var(--palette-white));
}

.button--danger {
	background-color: rgb(var(--danger));
	color: rgb(var(--palette-white));
}

.button--text {
	background: none;
	border: none;
	color: rgb(var(--palette-white));
}

/* Sizes */

.button--small {
	line-height: var(--small);
	min-width: var(--small);
	min-height: var(--small);
}

.button--full {
	width: 100%;
	line-height: var(--big);
	min-height: var(--big);
}

/* Group */

.button--group {
	--rgba-pink: rgba(var(--palette-pink), 1);

	/* Used hex as var as rgb on border doesn't work for ios safari */
	border: 2px solid var(--rgba-pink);
	border-right: none;
	background-color: rgb(var(--palette-white));
	flex: 1 1 auto;

	& + .button--group {
		margin-left: 0;
	}
}

.button--group:last-of-type {
	--rgba-pink: rgba(var(--palette-pink), 1);

	border-right: 2px solid var(--rgba-pink);
}

.button--selected {
	--rgba-pink: rgba(var(--palette-pink), 1);

	background-color: rgb(var(--palette-pink));
	color: rgb(var(--palette-white));
	/* Used hex as var as rgb on border doesn't work for ios safari */
	border: 1px solid var(--rgba-pink);
}
