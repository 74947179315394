.sticky-header {
	position: sticky;
	position: -webkit-sticky;
	top: 66px;

	&--navigation-not-visible {
		position: sticky;
		position: -webkit-sticky;
		top: 20px;
	}

	&--secondary {
		top: 110px;

		&.sticky-header--navigation-not-visible {
			top: 64px;
		}
	}
}

.not-sticky {
	position: relative;
	top: 0;
}
