.count-page {
	&__section {
		&__name-content {
			display: flex;
			flex-direction: column;
			justify-content: space-around;
			height: 50px;
		}

		&__invalid {
			background-color: rgba(var(--danger), 0.4);
		}
	}

	&__select-wrapper {
		display: block;
		position: relative;

		&::after {
			content: '>';
			transform: rotate(90deg);
			position: absolute;
			right: 0;
			top: 0;
			font-size: 24px;
			pointer-events: none;
		}

		&__select {
			background: none;
			padding: calc(var(--dp) / 2);
			border: none;
			color: rgb(var(--color-text-light));
			position: relative;
			appearance: none;
			padding-right: 20px;

			&:hover {
				cursor: pointer;
			}

			&:focus {
				outline: none;
			}

			span {
				line-height: 1;
			}

			&__description {
				color: rgba(var(--palette-dark-shade1), 0.7);
				font-size: 12px;
				line-height: 24px;
			}
		}

		&__invalid {
			background-color: rgba(var(--danger), 0.4);
		}
	}
}
