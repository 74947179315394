.areas-right {
	color: white;
	flex: 1;
	height: 100%;
	display: flex;
	flex-flow: column;
	justify-content: center;
	align-items: flex-end;
	background-color: rgb(var(--danger));

	&__delete {
		display: flex;
		flex-flow: column;
		align-items: center;
		height: 100%;

		width: 100%;
		max-width: 64px;
		text-align: center;
	}
}
