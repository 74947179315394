.letter-side-scroll {
	width: 22px;
	color: gray;
	text-align: center;
	user-select: none;
	display: flex;
	flex-direction: column;
	justify-content: flex-end;
	height: 100%;
	z-index: 6;

	&__letter {
		height: 3.1%;
		font-size: 12px;
	}
}
