/* ******* */
/* SLIDES */
/* ***** */

.slide-left-exit {
	z-index: 3;
	transform: translate(0%);
	transition: transform 150ms ease-in-out;
}

.slide-left-exit {
	transform: translate(0%);
}

.slide-left-exit.slide-left-exit-active {
	transform: translate(-100%);
	transition: transform 150ms ease-in-out;
}

.slide-right-enter {
	z-index: 3;
	transform: translateX(-100%);
}

.slide-right-enter.slide-right-enter-active {
	transform: translateX(0%);
	transition: transform 150ms ease-in-out;
}

.navigation-slide-enter {
	transform: translateX(-100%);
	z-index: 3;
}

.navigation-slide-enter.navigation-slide-enter-active {
	transform: translateX(0%);
	transition: transform 100ms ease-in-out;
}

.navigation-slide-exit {
	transform: translateX(0%);
}

.navigation-slide-exit.navigation-slide-exit-active {
	transform: translateX(-100%);
	transition: transform 100ms ease-in-out;
}

.slide-up-enter {
	transform: translateY(-100%);
}

.slide-up-enter.slide-up-enter-active {
	transform: translateY(0%);
	transition: transform 300ms ease-in-out;
}

.slide-up-exit {
	z-index: 3;
	transform: translateY(0%);
}

.slide-up-exit-active {
	transform: translateY(-100%);
	transition: transform 300ms ease-in-out;
}

.toast-up-enter {
	transform: translateY(100%);
}

.toast-up-enter.toast-up-enter-active {
	transform: translateY(0%);
	transition: transform 100ms ease-in-out;
}

.toast-up-exit {
	z-index: 3;
	transform: translateY(0%);
}

.toast-up-exit-active {
	transform: translateY(100%);
	transition: transform 100ms ease-in-out;
}

.letter-scroll-enter {
	top: 44px;
	transform: translateY(0px);
}

.letter-scroll-enter.letter-scroll-enter-active {
	transform: translateY(0px);
	transition: transform 100ms ease-in-out;
}

.letter-scroll-exit {
	top: 90px;
	transform: translateY(46px);
}

.letter-scroll-exit-active {
	transform: translateY(46px);
	transition: transform 100ms ease-in-out;
}

.fade-enter {
	opacity: 0;
}

.fade-enter.fade-enter-active {
	opacity: 1;
	transition: opacity 150ms ease-in-out;
}

.fade-exit {
	opacity: 1;
}

.fade-exit.fade-exit-active {
	opacity: 0;
	transition: opacity 150ms ease-in-out;
}

.letter-scroll-slide-enter {
	transform: translateX(100%);
}

.letter-scroll-slide-enter.letter-scroll-slide-enter-active {
	transform: translateX(0%);
	transition: transform 250ms ease-in-out;
}

.letter-scroll-slide-exit {
	transform: translateX(0%);
}

.letter-scroll-slide-exit.letter-scroll-slide-exit-active {
	transform: translateX(100%);
	transition: transform 250ms ease-in-out;
}

.expand-enter {
	width: 0%;
}

.expand-enter.expand-enter-active {
	width: 80%;
	transition: width 250ms ease-in-out;
}

.expand-exit {
	width: 80%;
}

.expand-exit.expand-exit-active {
	width: 0%;
	transition: width 250ms ease-in-out;
}
