:root {
	--palette-green: 147, 214, 168; /* #93d6a8 */
	--palette-green-shade1: 130, 205, 127;
	--palette-orange: 234, 121, 59;
	--palette-pink: 246, 197, 235; /* #F6C5EB */
	--palette-dark: 26, 26, 26; /* #1a1a1a */
	--palette-dark-shade1: 95, 95, 95; /* #5F5F5F */
	--palette-dark-shade2: 50, 50, 50; /* #323232 */
	--palette-light: 243, 243, 243; /* #f3f3f3 */
	--palette-light-shade1: 212, 212, 212; /* #D4D4D4 */
	--palette-white: 255, 255, 255; /* #ffffff */
	--palette-black: 0, 0, 0; /* #000000 */
	--palette-light-shade2: 236, 236, 236; /* #ECECEC */

	--danger: 239, 83, 80; /* #ef5350; */
	--warning: 244, 177, 39; /* #f4b127; */
	--success: 124, 179, 66; /* #7cb342; */

	/* Functionality */
	--color-primary: var(--palette-pink);
	--color-success: var(--palette-green);

	--color-background: var(--palette-dark);
	--color-background-light: var(--palette-light);
	--color-background-section: var(--palette-dark-shade1);

	--color-border-dark: var(--palette-dark-shade2);

	--color-text-light: var(--palette-white);
	--color-text-dark: var(--palette-dark-shade1);

	/* Sizing */
	--dp: 8px;

	/* Component specific */
	--nav-heigh: 66px;
}
