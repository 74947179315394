/* Joe & The Juice */
@import 'styles/vendor/normalize.css';
@import 'styles/utils.css';
@import 'styles/vars.css';
@import 'styles/animations.css';
@import '@sandstreamdev/react-swipeable-list/dist/styles.css';

html {
	background-color: #ffffff;
	background-size: 100%, 100px auto;
	font-family: 'Roboto Condensed', sans-serif;
	font-size: 16px;
	min-height: 100%;
	text-size-adjust: 100%;
	-webkit-tap-highlight-color: rgba(0, 0, 0, 0);
	-webkit-touch-callout: none;
	-webkit-text-size-adjust: none;
	height: 100%;
	width: 100%;
}

body {
	font-size: 100%;
	line-height: calc(22 / 16);
	margin: 0;
	overflow: hidden;
	height: 100%;
	position: relative;
	width: 100%;
	overscroll-behavior-y: contain;
}

a {
	background-color: transparent;
	color: currentColor;
	display: inline-block;
	outline: 0;
	text-decoration: none;
}

ul {
	list-style: none;
	margin: 0;
	padding: 0;
}

label {
	cursor: pointer;
}

h1,
h2,
h3 {
	font-size: 100%;
	font-weight: 400;
	line-height: inherit;
	margin: 0;
}

p {
	font-size: 100%;
	line-height: inherit;
	margin: 0;
}

* {
	-webkit-overflow-scrolling: touch;
}

.intro-message {
	--danger: 239, 83, 80; /* #ef5350; */

	width: 100%;
	height: 100vh;
	display: flex;
	align-items: center;
	justify-content: center;
	position: absolute;
	background-color: rgba(var(--palette-black), 0.85);
	z-index: 9999;

	& > div {
		text-align: center;
		max-width: 60%;
		padding: 12px;
		background-color: rgb(var(--danger));
		border-radius: 3px;
	}

	h2 {
		font-size: 18px;
		margin-bottom: 15px;
	}

	p {
		font-weight: 400;
		margin-bottom: 10px;
	}

	a {
		text-decoration: underline;
	}
}
