.order-justification {
  position: absolute;
  top: 66px;
  width: 100%;
  right: 0;
  bottom: 0;

  .justification-text-area {
    position: absolute;
    width: 100%;
    top: 0;
    left: 0;
    bottom: 0;
    padding: 25px;
    border: none;
    margin: 0;
    box-sizing: border-box;
  }
}