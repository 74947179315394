/* Flex */
.u-flex {
	display: flex;
	flex-direction: row;
}

.u-flexAlignCenter {
	align-items: center;
}

.u-flexJustifyCenter {
	justify-content: center;
}

.u-flexJustifyBetween {
	justify-content: space-between;
}

/* Manipulation of items */
.is-hidden {
	display: none;
}

.page-wrapper {
	background: rgb(var(--palette-white));
	width: 100%;
	position: absolute;
	top: 0;
	left: 0;
	bottom: 0;
	overflow-x: hidden;
	overflow-y: scroll;

	&::-webkit-scrollbar {
		display: none;
	}
}
