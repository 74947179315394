.confirmation-page {
  width: 100%;
  text-align: center;
  margin: auto;
  position: absolute;
  height: 130px;
  top: 35%;

  &__image {
    width: 100%;
    max-width: 400px;
    padding: 30px;
    box-sizing: border-box;
  }

  &__text {
    font-size: large;
    font-weight: 400;
  }
}

.button-container {
  position: absolute;
  width: 100%;
  bottom: 0;
}