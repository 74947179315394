.search-bar {
	position: relative;

	input {
		width: 100%;
		height: 44px;
		line-height: 44px;
		padding: 0 16px;
		border: none;
	}

	&__clear {
		position: absolute;
		top: 0;
		right: 0;
	}
}